@media (max-width: 1200px) {
    .auth-wrapper {
        flex-direction: column;
        align-items: center;

        .aside-wrapper {
            min-height: unset;
            width: 100%;
            padding: 0;
            justify-content: center !important;

            .login-aside {
                min-height: unset;
                flex-direction: row;
                height: auto;
                width: 100%;
                padding: 0;
                justify-content: center;
                align-items: center;
                box-shadow: unset;
                margin-bottom: 4px;

                li {
                    flex-direction: column-reverse;
                    align-items: center;
                    justify-content: center;
                    margin-top: 30px;

                    .item {
                        font-family: Druk Wide Cyr, serif;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 140%;
                        margin-top: 20px;
                        display: none;
                    }

                    .custom-ellipse:not(:last-of-type) {
                        margin-right: 19px;
                    }

                    .underline {
                        transform: rotate(0);
                        top: 14px;
                        right: 0;
                    }

                    .completed {
                        span {
                            &:after {
                                right: 30px;
                            }
                        }
                    }


                }
            }
        }

        .first-step {
            padding: 0;

            h1, h2 {
                text-align: center;
            }
            h1{
                margin-bottom: 30px;
            }

            .login-input {
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .custom-form-group-inline {
                    margin: 0 auto 20px auto;
                    input{
                        width: 345px;
                    }
                }
            }

            .social-form {

                h1 {
                    display: block;
                    text-align: center;
                }

                .social-btn-grp {
                    justify-content: center;
                    align-items: center;

                    button {
                        margin: 0 0 12px !important;
                    }
                }
            }

            .terms {
                display: flex;
                justify-content: center;

                span {
                    display: block;
                    position: relative;
                    min-width: 24px;

                }

                input {
                    position: relative;
                    left: 24px;
                    min-width: 24px;
                }

                label {
                    margin-left: 8px;
                }
            }
        }
    }
    .second-step {
        padding: 0;
        justify-content: center;

        h1, h2 {
            text-align: center;
        }

        .login-input {
            justify-content: center;
            display: flex;

            .gender-select {
                margin: 12px auto 53px auto;
            }
        }

        form {
            .target {
                .target-group {
                    flex-direction: column;
                    align-items: center;

                    .item {

                        margin-bottom: 12px;
                        margin-right: 0;

                        &:last-of-type {
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
    }
    .submit-group {
        flex-direction: column;
        display: flex;
        align-items: center;
        padding: 0 15px;

        a {
            //border: 1px solid #F0F712;
            box-sizing: border-box;
            min-width: 24px;
            height: 48px;
            width: 100%;
            margin-left: 15px !important;
            margin-right: 15px;
            margin-bottom: 50px;
            text-align: center;
            line-height: 48px;
        }


        .next {
            margin-bottom: 12px;
        }

        .prev.login-href {
            border: 1px solid rgba(240, 247, 18, 1);
            margin: auto auto 50px !important;
        }
    }
    .third-step {
        padding: 0;

        h1, h2 {
            text-align: center;
            width: 100%;
        }

        .personal-data {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            padding: 10px;

            .item {
                margin: 4.5px;
                width: 168px;

            }
        }
    }
    .four-step {
        padding: 0;

        h1, h2 {
            width: 100%;
            text-align: center;
        }

        .subscriptions {
            padding: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .item {
                width: 345px;
            }
        }

        .payment-wrapper {
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .item {
                width: 168px;
                margin: 4.5px;
            }
        }
    }
}

@media (max-width: 620px) {
    .first-step {
        .social-form {

            h1 {
                display: block;
                text-align: center;
            }

            .social-btn-grp {
                flex-direction: column;
                align-items: center;

                button {
                    margin: 0 0 12px !important;
                }
            }
        }
    }
    //.submit-group {
    //    .next {
    //        width: 100%;
    //    }
    //}
}

@media (max-width: 550px) {
    .third-step {
        .personal-data {
            .item:last-of-type {
                margin-right: 181px;
            }
        }
    }
}
@media (max-width: 450px) {
    .third-step {
        .personal-data {
            .item:last-of-type {
                margin-right: 154px;
            }
        }
    }
}

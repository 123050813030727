@media (max-width: 1200px) {
  .auth-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .auth-wrapper .aside-wrapper {
    min-height: unset;
    width: 100%;
    padding: 0;
    justify-content: center !important;
  }
  .auth-wrapper .aside-wrapper .login-aside {
    min-height: unset;
    flex-direction: row;
    height: auto;
    width: 100%;
    padding: 0;
    justify-content: center;
    align-items: center;
    box-shadow: unset;
    margin-bottom: 4px;
  }
  .auth-wrapper .aside-wrapper .login-aside li {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
  .auth-wrapper .aside-wrapper .login-aside li .item {
    font-family: Druk Wide Cyr, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    margin-top: 20px;
    display: none;
  }
  .auth-wrapper .aside-wrapper .login-aside li .custom-ellipse:not(:last-of-type) {
    margin-right: 19px;
  }
  .auth-wrapper .aside-wrapper .login-aside li .underline {
    transform: rotate(0);
    top: 14px;
    right: 0;
  }
  .auth-wrapper .aside-wrapper .login-aside li .completed span:after {
    right: 30px;
  }
  .auth-wrapper .first-step {
    padding: 0;
  }
  .auth-wrapper .first-step h1, .auth-wrapper .first-step h2 {
    text-align: center;
  }
  .auth-wrapper .first-step h1 {
    margin-bottom: 30px;
  }
  .auth-wrapper .first-step .login-input {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .auth-wrapper .first-step .login-input .custom-form-group-inline {
    margin: 0 auto 20px auto;
  }
  .auth-wrapper .first-step .login-input .custom-form-group-inline input {
    width: 345px;
  }
  .auth-wrapper .first-step .social-form h1 {
    display: block;
    text-align: center;
  }
  .auth-wrapper .first-step .social-form .social-btn-grp {
    justify-content: center;
    align-items: center;
  }
  .auth-wrapper .first-step .social-form .social-btn-grp button {
    margin: 0 0 12px !important;
  }
  .auth-wrapper .first-step .terms {
    display: flex;
    justify-content: center;
  }
  .auth-wrapper .first-step .terms span {
    display: block;
    position: relative;
    min-width: 24px;
  }
  .auth-wrapper .first-step .terms input {
    position: relative;
    left: 24px;
    min-width: 24px;
  }
  .auth-wrapper .first-step .terms label {
    margin-left: 8px;
  }

  .second-step {
    padding: 0;
    justify-content: center;
  }
  .second-step h1, .second-step h2 {
    text-align: center;
  }
  .second-step .login-input {
    justify-content: center;
    display: flex;
  }
  .second-step .login-input .gender-select {
    margin: 12px auto 53px auto;
  }
  .second-step form .target .target-group {
    flex-direction: column;
    align-items: center;
  }
  .second-step form .target .target-group .item {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .second-step form .target .target-group .item:last-of-type {
    margin-bottom: 30px;
  }

  .submit-group {
    flex-direction: column;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
  .submit-group a {
    box-sizing: border-box;
    min-width: 24px;
    height: 48px;
    width: 100%;
    margin-left: 15px !important;
    margin-right: 15px;
    margin-bottom: 50px;
    text-align: center;
    line-height: 48px;
  }
  .submit-group .next {
    margin-bottom: 12px;
  }
  .submit-group .prev.login-href {
    border: 1px solid #f0f712;
    margin: auto auto 50px !important;
  }

  .third-step {
    padding: 0;
  }
  .third-step h1, .third-step h2 {
    text-align: center;
    width: 100%;
  }
  .third-step .personal-data {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
  }
  .third-step .personal-data .item {
    margin: 4.5px;
    width: 168px;
  }

  .four-step {
    padding: 0;
  }
  .four-step h1, .four-step h2 {
    width: 100%;
    text-align: center;
  }
  .four-step .subscriptions {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .four-step .subscriptions .item {
    width: 345px;
  }
  .four-step .payment-wrapper {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .four-step .payment-wrapper .item {
    width: 168px;
    margin: 4.5px;
  }
}
@media (max-width: 620px) {
  .first-step .social-form h1 {
    display: block;
    text-align: center;
  }
  .first-step .social-form .social-btn-grp {
    flex-direction: column;
    align-items: center;
  }
  .first-step .social-form .social-btn-grp button {
    margin: 0 0 12px !important;
  }
}
@media (max-width: 550px) {
  .third-step .personal-data .item:last-of-type {
    margin-right: 181px;
  }
}
@media (max-width: 450px) {
  .third-step .personal-data .item:last-of-type {
    margin-right: 154px;
  }
}
@media (max-width: 1750px) {
  .information-content .personal-params {
    flex-direction: column;
  }
  .information-content .personal-params .col-4 {
    max-width: 100% !important;
    justify-content: center;
  }
  .information-content .personal-params .col-4 .personal-params-item, .information-content .personal-params .col-4 .personal-params-item-update {
    max-width: 20%;
    min-width: 150px;
    padding: 30px 20px 20px 20px;
  }
  .information-content .personal-params-item-update button {
    padding: 12px 0;
  }
  .information-content .chart-div {
    max-width: 100%;
  }
}
@media (max-width: 1048px) {
  .subscribe-header H2 {
    font-family: Druk Wide Cyr;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }

  .information-content .col-10 {
    margin: 0 !important;
    max-width: 100%;
  }
  .information-content .personal-head {
    flex-direction: column;
  }
  .information-content .personal-head .personal-head-img {
    margin-right: 0;
  }
  .information-content .personal-head .personal-head-name, .information-content .personal-head .personal-head-gender {
    margin-right: 0;
  }
  .information-content .subscribe-header h2 {
    width: 100%;
    text-align: center;
  }
  .information-content .personal-numbers {
    flex-direction: column;
  }
  .information-content .personal-numbers .item {
    max-width: 100%;
    flex: 0;
  }
  .information-content .personal-numbers .item h5 {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-align: center;
  }
  .information-content .personal-params .personal-params-item {
    max-width: 50% !important;
    min-width: 150px;
    padding: 30px 41px 20px 41px;
  }
  .information-content .personal-params .personal-params-item p {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  .information-content .personal-params .personal-params-item h6 {
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
  }
  .information-content .personal-params .personal-params-item-update {
    max-width: 50% !important;
    min-width: 150px;
  }
  .information-content .personal-params .personal-params-item-update h2 {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }
  .information-content .personal-params .personal-params-item-update p {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
  }
  .information-content .personal-video h2 {
    font-family: Druk Wide Cyr;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
}
@media (max-width: 450px) {
  .information-content .col-xl-10 {
    padding: 0;
  }
  .information-content .personal-params-item, .information-content .personal-params-item-update {
    margin: 0 auto 20px auto;
  }
  .information-content .personal-params-item {
    padding-right: 20px;
    padding-left: 20px;
  }
  .information-content .personal-params-item-update {
    margin: 0 auto 20px auto;
  }

  .food-content .food-category-content-item {
    padding: 10px;
  }

  .mainNav .navClient a {
    margin-left: 10px;
  }

  .four-step .subscriptions {
    padding: 0;
  }
  .four-step .subscriptions .item {
    width: 310px;
  }
  .four-step .payment-subscription .payment-wrapper .item {
    margin: 0 auto 5px auto;
  }

  .third-step .personal-data .item {
    width: 140px;
  }
  .third-step .personal-data .item .plus-minus-group {
    display: flex;
    justify-content: space-around;
  }
  .third-step .personal-data .item .plus-minus-group span {
    margin: 0;
  }

  .sideCalendar {
    min-width: unset;
    max-width: 450px;
    width: 100%;
    padding: 0 15px;
  }

  .cardForFitness {
    max-width: 280px !important;
  }

  .program .owl-item {
    width: 280px !important;
  }
}
@media (max-width: 1780px) {
  .subscribe-content .subscribe-new-plan-price div {
    max-width: calc(50% - 11px);
  }
}
@media (max-width: 1900px) {
  .program-content .program-content-item {
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  .program-content .item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 20px;
  }

  .burn-content .youtube-save, .burn-content .youtube-header, .functional-content .youtube-save, .functional-content .youtube-header {
    flex-wrap: wrap-reverse;
  }
  .burn-content .youtube-save .col-12, .burn-content .youtube-header .col-12, .functional-content .youtube-save .col-12, .functional-content .youtube-header .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 1593px) {
  .mainNav .navList {
    justify-content: space-around;
  }
  .mainNav .navList a {
    margin-right: 0 !important;
  }
}
@media (max-width: 1500px) {
  .mainNav .nav .dropdown-menu a:nth-of-type(3) {
    display: flex !important;
  }
  .mainNav .nav li:nth-of-type(5) {
    display: none;
  }
  .mainNav .nav .dropdown {
    display: block !important;
    margin-right: 35px;
  }
  .mainNav .nav #mainButtonResponsive {
    display: block !important;
    height: inherit;
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 1470px) {
  .mainNav .dropdown-menu a:nth-of-type(2) {
    display: flex !important;
  }
  .mainNav .nav li:nth-of-type(4) {
    display: none;
  }
}
@media (max-width: 1440px) {
  .aboutThird {
    max-width: 100%;
    padding: 0;
  }
  .aboutThird .aboutThird-content {
    flex-direction: column;
    padding: 0;
  }
  .aboutThird .aboutThird-content .aboutComment1 {
    max-width: 100%;
    flex-wrap: wrap;
    padding: 0;
  }
  .aboutThird .aboutThird-content .aboutComment2 {
    max-width: 100%;
    padding: 0;
  }

  .program-content .item {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }
}
@media (max-width: 1350px) {
  #sideMenuOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    background: #00000075;
  }
  #sideMenuOverlay svg {
    right: 50px;
    position: absolute;
    top: 20px;
  }
  #sideMenuOverlay svg:hover {
    cursor: pointer;
  }

  .sideMenu {
    position: fixed;
    z-index: 4;
    background: white;
    height: 100%;
    top: 0;
  }
  .sideMenu .sideNavTop {
    display: flex;
    flex-direction: column;
  }
  .sideMenu .sideNavTop a {
    font-family: Druk Wide Cyr, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: #111111;
    padding: 19px 15px 19px 15px;
  }
  .sideMenu .sideNavTop .active {
    background: #111111;
    color: #FFFFFF;
  }
}
@media (max-width: 1314px) {
  .food-filter .food-select .selects div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: 1250px) {
  .mainNav .nav .dropdown-menu a:nth-of-type(1) {
    display: flex !important;
  }
  .mainNav .nav li:nth-of-type(3) {
    display: none;
  }
}
@media (max-width: 1157px) {
  .mainNavUser .menu {
    display: flex;
    margin: auto 30px auto 15px !important;
    padding: 0;
  }
  .mainNavUser .navHome {
    margin-left: 0 !important;
    padding: 0 !important;
    border: none !important;
  }
  .mainNavUser .navClient .language {
    display: none;
  }
  .mainNavUser .navClient .clientCabinet div:first-of-type {
    display: none;
  }
  .mainNavUser .navClient .clientCabinet div:last-of-type {
    margin: auto 15px auto 18px;
  }
  .mainNavUser .navClient .clientCabinet div {
    width: 34px;
    height: 34px;
  }
  .mainNavUser .navClient .clientCabinet img {
    width: 28px;
    height: 28px;
  }
  .mainNavUser .navClient .d-flex {
    display: none !important;
  }
  .mainNavUser .navClient button {
    width: 22px !important;
    height: 22px !important;
    background: none;
    padding: 0;
    margin: 0 25px 0 25px;
    line-height: 0;
  }
  .mainNavUser .navClient button span {
    display: none;
  }

  .sideNav {
    padding: 40px 0 0 0;
    display: none;
    width: 253px;
    justify-content: unset;
  }
  .sideNav .language {
    display: table !important;
  }
  .sideNav .sideNavProfile {
    display: block;
    margin-bottom: 12px;
  }
  .sideNav .sideNavTop {
    margin-bottom: auto;
  }
  .sideNav .sideNavBottom {
    padding: 0 77px 20px 28px;
  }
  .sideNav .language {
    margin-bottom: 20px;
    margin-top: 20px;
    border-top: 1px solid #E6E6E6;
  }

  .program-content, .food-content, .achievements-content, .information-content, .subscribe-content, .burn-content, .functional-content {
    left: unset;
    width: 100%;
    padding-top: 90px;
  }

  .program-content .program-content-item h2 {
    text-align: center;
  }
  .program-content .program-content-item .cardForFitness {
    margin: auto !important;
  }

  .achievements-content .items {
    margin-bottom: 10px;
  }
  .achievements-content .items .item {
    max-width: 168px;
    min-width: unset;
  }
  .achievements-content .items .item h1 {
    font-family: Druk Wide Cyr;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
  }

  .subscribe-content .subscribe-header {
    margin-left: 15px;
  }
  .subscribe-content .subscribe-transform {
    flex-direction: column;
    margin: auto;
    max-width: calc(100% - 30px);
  }
  .subscribe-content .subscribe-transform h3 {
    font-family: Druk Wide Cyr;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  .subscribe-content .subscribe-transform p {
    font-size: 12px;
  }
  .subscribe-content .subscribe-transform * {
    max-width: 313px !important;
  }
  .subscribe-content .subscribe-new-plan-header {
    max-width: calc(100% - 30px);
  }
  .subscribe-content .subscribe-new-plan-header h2 {
    font-family: Druk Wide Cyr;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
  .subscribe-content .subscribe-new-plan-price {
    margin-bottom: 28px;
  }

  .burn-content .content-user-hide button, .functional-content .content-user-hide button {
    width: 100%;
    margin: 20px auto 20px auto;
  }
  .burn-content .content-user-image-small, .functional-content .content-user-image-small {
    width: 38px;
    height: 38px;
  }
  .burn-content .bg-white p, .functional-content .bg-white p {
    font-family: Montserrat;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
  }
  .burn-content .bg-white h1, .functional-content .bg-white h1 {
    font-family: Druk Wide Cyr;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
  }
  .burn-content .content-user-img, .functional-content .content-user-img {
    width: 60px;
    height: 60px;
  }
  .burn-content .content-user-bg .content-user, .functional-content .content-user-bg .content-user {
    padding: 20px 0;
  }

  .userFooter {
    width: 100%;
    left: unset;
  }
  .food-content .food-select-button {
    width: 90% !important;
    max-width: 100%;
    margin-bottom: 26px;
  }

  .food-category-content-alert {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .food-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100% !important;
  }
  .food-select div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin: 0 !important;
  }
  .food-select div select {
    max-width: 90% !important;
    margin: 0 0 20px;
  }
  .food-select div label {
    width: 90%;
    text-align: right;
  }

  .food-filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
  }
}
@media (max-width: 1078px) {
  .about .aboutFirst, .about .aboutSecond {
    max-width: 100%;
  }
  .about .imgAbsolute {
    position: unset !important;
  }
  .about .imgAbsolute .img1 {
    display: none;
  }
  .about .imgAbsolute .img2 {
    position: unset !important;
  }
}
@media (max-width: 1260px) and (min-width: 959px) {
  #subscribe .container-fluid .col-md-6 {
    flex: 0 0 50%;
    max-width: 50% !important;
  }
}
@media (max-width: 991px) {
  main {
    padding-top: 80px;
  }

  .mainNav {
    position: fixed;
    width: 100%;
    z-index: 4;
    opacity: 0.9;
  }
  .mainNav .navHome {
    margin-left: 20px;
  }
  .mainNav #mainButtonResponsive {
    display: none;
  }
  .mainNav #mainButton {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 64px;
    height: inherit;
    margin: auto;
    padding: 0;
  }

  .subscribe-content .subscribe-new-plan-price div {
    margin-right: 0 !important;
    max-width: 638px !important;
  }

  .sectionMain .sectionText {
    top: 50%;
    left: 38px;
    transform: translateY(-50%);
  }
  .sectionMain .sectionText h1 {
    Line-height: 30px;
    font-size: 22px;
  }
  .sectionMain .sectionText button {
    font-size: 15px;
    max-width: 160px;
    max-height: 38px;
    margin: 0;
  }

  .userFooter div:first-child {
    padding: 0;
    margin-bottom: 12px;
  }
  .userFooter p {
    text-align: left;
  }
  .userFooter .user-content {
    flex-direction: column;
    margin-left: -15px;
    max-width: 100%;
  }
  .userFooter .copyright {
    margin-left: 15px;
  }
  .userFooter .promodex {
    margin-left: 15px;
    justify-content: flex-start !important;
  }

  .burn-content .content-user-video div:last-child {
    padding: 0 !important;
  }
  .burn-content .content-user-video .content-user-video-item {
    padding: 0;
  }
  .burn-content .content-user-video .content-user-video-item h2 {
    font-size: 11px !important;
    line-height: 15px !important;
  }
  .burn-content .youtube-header h3 {
    font-family: Druk Wide Cyr;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }
  .burn-content .youtube-save {
    margin-bottom: 40px;
  }
  .burn-content .answer {
    padding-left: 0 !important;
  }

  .functional-content .youtube-training h2 {
    font-family: Druk Wide Cyr;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .functional-content .content-user-video .content-user-video-item {
    padding: 0;
  }
  .functional-content .content-user-video .content-user-video-item h2 {
    font-size: 11px !important;
    line-height: 15px !important;
  }
  .functional-content .youtube-header h3 {
    font-family: Druk Wide Cyr;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }
  .functional-content .youtube-save {
    margin-bottom: 40px;
  }
  .functional-content .answer {
    padding-left: 0 !important;
  }
}
@media (max-width: 766px) {
  .program-content h2 {
    width: 100%;
    text-align: center;
  }
  .program-content .program-content-item {
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  .program-content .item {
    display: flex;
    justify-content: center;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  main {
    padding-top: 60px;
  }

  .mainNav, .mainNavUser {
    height: 60px !important;
  }
  .mainNav .navHome img, .mainNavUser .navHome img {
    width: 92px !important;
    height: 36px !important;
  }
  .mainNav .navClient a, .mainNavUser .navClient a {
    height: 60px;
  }
  .mainNav select, .mainNavUser select {
    font-size: 12px !important;
  }

  .sectionMain .sectionText {
    width: 85% !important;
  }
  .sectionMain .sectionText a {
    max-width: 183px;
    height: 48px;
    padding: 0;
  }

  .subscribe h6 {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-align: center;
  }
  .subscribe h1 {
    font-family: Druk Wide Cyr;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
  .subscribe .subscribe-item {
    padding-left: 21px !important;
    max-width: 375px;
    max-height: 240px;
    background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 75.78%), url(/images/subscribe.png);
  }
  .subscribe .subscribe-item:not(:last-child) {
    margin-bottom: 20px !important;
  }
  .subscribe .subscribe-item h3 {
    width: 60%;
    font-family: Druk Wide Cyr, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25.2px;
    letter-spacing: 0;
    text-align: left;
    color: #FFFFFF;
  }
  .subscribe .subscribe-item p {
    font-family: Montserrat, serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 66px;
    letter-spacing: 0;
    text-align: center;
    color: #FFFFFF;
  }
  .subscribe .subscribe-item p span {
    font-family: Montserrat, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0;
    text-align: center;
    position: relative;
    top: -20px;
  }
  .subscribe .subscribe-item button {
    border-radius: unset !important;
    max-width: 183px;
    width: 100%;
    padding: 15px 60px;
    height: 60px;
    font-family: Druk Wide Cyr, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
    margin: 0;
  }

  .about .aboutFirst h1 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 6px !important;
  }
  .about .aboutFirst p {
    font-size: 14px;
    line-height: 19.6px;
    margin-bottom: 14px !important;
  }
  .about .aboutSecond {
    flex-direction: column;
    align-content: center;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .about .aboutSecond h1 {
    text-align: center;
    max-width: 100%;
    font-size: 30px;
    line-height: 36px;
  }
  .about .aboutSecond p {
    text-align: center;
    max-width: 100%;
    font-size: 14px;
    line-height: 16.8px;
    margin-bottom: 20px;
  }
  .about .aboutThird {
    margin-bottom: 50px;
  }
  .about .aboutThird h2 {
    font-family: Druk Wide Cyr;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  .about .aboutThird p {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
  }
  .about .aboutThird h1 {
    font-family: Druk Wide Cyr;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
  .about .aboutThird .aboutThird-back {
    top: 90px;
    right: 0 !important;
  }
  .about .aboutThird .aboutThird-back img {
    margin: 0 !important;
  }
  .about .aboutThird .aboutThird-back img:last-child {
    display: none;
  }

  .gym .gymTitle {
    margin-bottom: 20px !important;
  }
  .gym .onlineGym {
    display: none;
  }
  .gym h6 {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-align: center;
  }
  .gym h1 {
    font-family: Druk Wide Cyr;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
  .gym p {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  .program .program-header h1 {
    font-family: Druk Wide Cyr;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
  .program .cardHeader {
    font-family: Druk Wide Cyr;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .expert h2 {
    font-family: Druk Wide Cyr;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 50px;
  }
  .expert h4 {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: center;
    margin-bottom: 20px;
  }
  .expert .trainer h1 {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .expert .trainer h3 {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
  }

  .news {
    margin-top: 50px;
  }
  .news h4 {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-align: center;
  }
  .news h2 {
    font-family: Druk Wide Cyr;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px;
  }
  .news h3 {
    font-family: Druk Wide Cyr;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .news p {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.03em;
    text-align: left;
  }

  .seo {
    padding: 0;
    margin-bottom: 50px;
  }
  .seo h1 {
    font-family: Druk Wide Cyr;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  .seo h3 {
    font-family: Druk Wide Cyr;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }
  .seo p {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .seo p:first-child {
    margin-bottom: 50px;
  }

  footer {
    padding-top: 40px;
  }
  footer .footerPromo {
    margin-bottom: 0 !important;
  }
  footer .footerPromo h6 {
    margin: 0 0 17px;
  }
  footer .footerClient {
    margin-bottom: 32px;
  }
  footer .footer-main-link {
    margin-bottom: 30px;
  }
  footer ul {
    align-items: center;
  }
  footer ul a {
    max-height: 18px;
    margin-right: 0 !important;
    margin-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
  }
}
@media (max-width: 576px) {
  header .navSearch {
    margin-left: auto !important;
    margin-right: unset !important;
  }
  header .navSearch input {
    display: none;
  }
  header .navSearch span {
    line-height: inherit !important;
  }
  header .navClient button {
    margin: 0px 0px 0 0px !important;
  }
  header .navClient .clientCabinet div:last-of-type {
    margin: auto 13px auto 18px;
  }

  #program .program-header {
    margin-top: 50px !important;
  }

  .navClient {
    width: auto !important;
    width: auto;
    justify-content: flex-end;
  }
  .navClient a {
    width: auto;
    padding: 18px 25px;
  }
  .navClient span {
    display: none;
  }

  .gymImages {
    width: 100%;
  }
  .gymImages .gymItem {
    width: 100%;
    margin: 6px auto;
    height: 60px;
    min-width: 258px;
  }
  .gymImages .yellowUnderline {
    display: none;
  }
  .gymImages p {
    margin: 0 0 0 20px !important;
    text-align: left !important;
  }
  .gymImages .gymSvgItems {
    width: 60px;
    height: auto !important;
    margin-left: 15px;
    display: flex;
    justify-content: center;
  }

  .achievements-content .items {
    justify-content: center;
  }

  .main-slider-item .img-big {
    display: none !important;
  }
  .main-slider-item .img-small {
    display: block !important;
  }

  .about .opg-numbers .opg-number {
    max-width: unset !important;
    flex-direction: column;
    width: auto;
    margin-bottom: 20px;
  }
  .about .opg-numbers .opg-number p {
    text-align: center;
  }
  .about .opg-numbers .opg-number span {
    margin-bottom: 6px;
  }
}
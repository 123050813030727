@media (max-width: 1750px) {
    .information-content {

        .personal-params {
            flex-direction: column;

            .col-4 {
                max-width: 100% !important;
                justify-content: center;

                .personal-params-item,.personal-params-item-update {
                    max-width: 20%;
                    min-width: 150px;
                    padding: 30px 20px 20px 20px ;
                }
            }
        }


        .personal-params-item-update button {
            padding: 12px 0;
        }

        .chart-div {
            max-width: 100%;
        }
    }
}

@media (max-width: 1048px) {
    .subscribe-header {
        H2 {
            font-family: Druk Wide Cyr;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: center;
        }
    }
    .information-content {
        .col-10 {
            margin: 0 !important;
            max-width: 100%;
        }

        .personal-head {
            flex-direction: column;

            .personal-head-img {
                margin-right: 0;
            }

            .personal-head-name, .personal-head-gender {
                margin-right: 0;
            }
        }

        .subscribe-header {
            h2 {
                width: 100%;
                text-align: center;
            }
        }

        .personal-numbers {
            flex-direction: column;

            .item {
                max-width: 100%;
                flex: 0;

                h5 {
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 12px;
                    letter-spacing: 0.03em;
                    text-align: center;
                }
            }
        }

        .personal-params {
            .personal-params-item {
                max-width: 50% !important;
                min-width: 150px;
                padding: 30px 41px 20px 41px ;
                p {
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: center;
                }

                h6 {
                    font-family: Montserrat;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 50px;
                    letter-spacing: 0em;
                    text-align: center;
                }
            }

            .personal-params-item-update {
                max-width: 50% !important;
                min-width: 150px;

                h2 {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: center;

                }

                p {
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: center;

                }
            }
        }

        .personal-video {
            h2 {
                font-family: Druk Wide Cyr;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: center;

            }
        }
    }
}

@media (max-width: 450px) {
    .information-content {
        .col-xl-10 {
            padding: 0;
        }

        .personal-params-item, .personal-params-item-update {
            margin: 0 auto 20px auto;
        }

        .personal-params-item {
            padding-right: 20px;
            padding-left: 20px;
        }

        .personal-params-item-update {
            margin: 0 auto 20px auto;
        }
    }
    .food-content {
        .food-category-content-item {
            padding: 10px;
        }
    }
    .mainNav {
        .navClient {
            a {
                margin-left: 10px;
            }
        }
    }
    .burn-content{

    }
    .four-step {
        .subscriptions {
            padding: 0;

            .item {
                width: 310px;
            }
        }

        .payment-subscription {
            .payment-wrapper {
                .item {
                    margin: 0 auto 5px auto;
                }
            }
        }
    }
    .third-step {
        .personal-data {
            .item {
                width: 140px;

                .plus-minus-group {
                    display: flex;
                    justify-content: space-around;

                    span {
                        margin: 0;
                    }
                }
            }
        }
    }
    .sideCalendar{
        min-width: unset;
        max-width: 450px;
        width: 100%;
        padding: 0 15px;
    }
    .cardForFitness{
        max-width: 280px!important;
    }
    .program{
        .owl-item{
            width: 280px!important;
        }
    }

}
